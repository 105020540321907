import {
  computed,
  onActivated,
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  onMounted,
  onUnmounted
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { getters } = useStore()
  const windowWidth = ref(window.innerWidth)
  const state = reactive({
    category2List: computed(() => getters['nav/getCategory2List']),
    listScrollTop: 0,
    winWidth: computed(() => windowWidth.value)
  })

  const onWidthChange = () => (windowWidth.value = window.innerWidth)
  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  const fnView = async item => {
    if (item.categoryId < 2000) {
      const pageCon = document.querySelector('#page_con')
      if (pageCon) {
        state.listScrollTop = pageCon.scrollTop
      }
      await router.push({
        path: '/party/view',
        query: { partyNo: item.partyNo || item.productNo }
      })
    }
  }

  onActivated(() => {
    if (!proxy.$Util.isEmpty(state.listScrollTop) && state.listScrollTop > 0) {
      document.querySelector('#page_con').scrollTop = state.listScrollTop
      state.listScrollTop = 0
    } else {
      proxy.$emit('reset')
    }
  })

  return { ...toRefs(state), fnView }
}
