import {
  computed,
  onActivated,
  reactive,
  toRefs,
  getCurrentInstance
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    items: props.items,
    category2List: computed(() => getters['nav/getCategory2List']),
    listScrollTop: 0
  })

  const fnView = async item => {
    const pageCon = document.querySelector('#page_con')
    if (pageCon) {
      state.listScrollTop = pageCon.scrollTop
    }
    await router.push({
      path: '/market/view',
      query: { marketNo: item.marketNo }
    })
  }

  const computedPer = (price, commonPrice) => {
    const getDiff = price / commonPrice
    let per = ''
    let className = {}
    if (getDiff < 1) {
      per = '-' + per + Math.round((1 - getDiff) * 100) + '%'
      className = { per: true }
    } else if (getDiff > 1) {
      per = '+' + per + Math.round((getDiff - 1) * 100) + '%'
      className = { perplus: true }
    } else if (getDiff == 1) {
      per = '0%'
      className = { per: true }
    }

    return { per, className }
  }

  onActivated(() => {
    if (!proxy.$Util.isEmpty(state.listScrollTop) && state.listScrollTop > 0) {
      document.querySelector('#page_con').scrollTop = state.listScrollTop
      state.listScrollTop = 0
    } else {
      proxy.$emit('reset')
    }
  })

  return { ...toRefs(state), fnView, computedPer }
}
