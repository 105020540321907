<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="my_page_home">
            <div class="inner">
              <div class="block full">
                <div class="left">
                  <div
                    class="prof"
                    :class="[
                      {
                        off: leader.userLvNm === $ConstCode.LEVEL_TYPE['0'].name
                      },
                      {
                        vip: leader.sellerLv >= 3
                      },
                      {
                        nonebg:
                          !$Util.isEmpty(leader.profile) &&
                          leader.profile.indexOf('/null') < 0
                      }
                    ]"
                  >
                    <!--'미인증: off 추가', '우수회원: vip 추가'-->
                    <div class="img_area">
                      <img
                        :src="leader.profile"
                        alt=""
                        v-if="
                          !$Util.isEmpty(leader.profile) &&
                            leader.profile.indexOf('/null') < 0
                        "
                      />
                    </div>
                  </div>
                  <div class="nickname">{{ leader.userNm }}</div>
                </div>
              </div>
              <div
                class="block vert vert_type02 pc"
                :class="{ selected: index == 0 }"
                @click="fnChangingTab(0)"
              >
                <!-- 선택된 탭 seleted 추가 -->
                <div class="left">
                  <div class="block_ttl">구독</div>
                </div>
                <div class="right">
                  <span>{{ partyCnt }}</span>
                </div>
              </div>
              <div
                class="block vert vert_type02 pc"
                :class="{ selected: index == 1 }"
                @click="fnChangingTab(1)"
              >
                <div class="left">
                  <div class="block_ttl">마켓</div>
                </div>
                <div class="right">
                  <span>{{ marketCnt }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="index == 0 && !$Util.isEmpty(partyList)"
              class="party_list_wrap"
            >
              <div class="list_top">
                <div class="left">
                  <div class="list_top_ttl">구독</div>
                </div>
              </div>
              <PartyList :row-type="1" :items="partyList" />
            </div>
            <div
              v-else-if="index == 1 && !$Util.isEmpty(marketList)"
              class="party_list_wrap"
            >
              <div class="list_top">
                <div class="left">
                  <div class="list_top_ttl">마켓</div>
                </div>
              </div>
              <MarketList :row-type="1" :items="marketList" />
            </div>
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>
                  {{ index == 0 ? '구독' : '마켓' }} 검색 결과가 없습니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
import PartyList from '@/components/views/party/list/index.vue'
import MarketList from '@/components/views/market/list/index.vue'
export default {
  name: 'main-trading-index',
  components: {
    PartyList,
    MarketList
  },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
