import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    productType: route.params.type,
    productNo: route.params.productNo,
    index: route.query.index || 0,
    leader: {
      profile: '',
      userLvNm: '',
      userNm: ''
    },
    partyCnt: 0,
    partyList: [],
    marketCnt: 0,
    marketList: []
  })

  const tradingProduct = async () => {
    const params = {
      productType: state.productType,
      productNo: state.productNo
    }
    const res = await proxy.$MainSvc.postTradingProduct(params)
    if (res.resultCode === '0000') {
      state.leader = res.leader
      state.partyCnt = res.partyCnt
      state.marketCnt = res.marketCnt
      state.partyList = res.partyList
      state.marketList = res.marketList
    } else {
      alert(res.resultMsg)
    }
  }

  const fnChangingTab = index => {
    router.push({
      name: 'trading-index',
      params: {
        type: state.productType,
        productNo: state.productNo
      },
      query: {
        index
      }
    })
  }

  const init = async () => {
    await tradingProduct()
  }
  init()
  return {
    ...toRefs(state),
    fnChangingTab
  }
}
