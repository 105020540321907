<template>
  <div class="party_list" :class="{ row_type: rowType === 2 }">
    <!--'가로형: row_type'-->
    <ul class="party_list_ul">
      <li
        class="party_list_li"
        :class="{ disabled: Number(item.status) === 1 }"
        v-for="(item, index) in items"
        :key="index"
        @click="fnView(item)"
      >
        <div class="party_tag">
          <div class="left">
            <div
              class="pt_tag logo"
              style="--pt-txt-color: #fff;"
              :style="{
                '--pt-bg-color': $ConstCode.getCategoryById(item.categoryId)
                  .color
              }"
            >
              {{ item.categoryNm }}
            </div>
            <div
              class="pt_tag normal nb"
              :class="[{ blue: item.acntTransferYn === 'Y' }]"
              v-if="item.categoryId < 2000"
            >
              {{
                item.acntTransferYn === 'Y' ? '양도' : `${item.recruitNum}명`
              }}
            </div>
            <div class="pt_tag normal nb" v-else>
              {{ item.quantity ? item.quantity : 0 }}
            </div>
          </div>
          <div class="right" style="display: flex;">
            <div v-if="rowType == 2 && item.sellerLv >= 3" class="directly vip">
              V
            </div>
            <div v-if="rowType == 2 && item.shareYn == 'N'" class="directly">
              즉
            </div>
          </div>
        </div>
        <div class="party_ttl">
          {{ item.title }}
        </div>
        <div class="party_con">
          <div class="left">
            <div class="party_info">
              <dl>
                <dt>기간 :</dt>
                <dd
                  v-if="
                    item.unlimitedCheckYn === 'N' && item.acntTransferYn === 'N'
                  "
                >
                  {{
                    `${item.usePeriod}일 (${$Util.formatNumber2(
                      item.dailyPrice,
                      {
                        unit: '원'
                      }
                    )}/일)`
                  }}
                </dd>
                <dd
                  v-else-if="
                    item.unlimitedCheckYn === 'N' && item.acntTransferYn === 'Y'
                  "
                >
                  {{ item.useDt }} 까지
                </dd>
                <dd v-else>무제한 또는 별도 표시</dd>
              </dl>
            </div>
          </div>
          <div class="right">
            <div class="party_price" style="flex: 1 auto;">
              <span class="value" v-if="item.acntTransferYn === 'N'">{{
                $Util.formatNumber2(
                  Number(item.usePeriod) * Number(item.dailyPrice),
                  { unit: '' }
                )
              }}</span
              ><!--띄어쓰기-->
              <span class="value" v-else>{{
                $Util.formatNumber2(Number(item.dailyPrice), { unit: '' })
              }}</span>
              <span class="unit">원</span>
              <!-- 명 제외 20210809 -->
              {{ Number(item.status) === 1 ? '(마감)' : '' }}
            </div>
            <div v-if="rowType == 1 && item.sellerLv >= 3" class="directly vip">
              V
            </div>
            <div v-if="rowType == 1 && item.shareYn == 'N'" class="directly">
              즉
            </div>
          </div>
        </div>
        <!-- 유투브 추가 리스트 -->
        <!-- <div class="party_list" style="padding: 0;" v-else>
          <div
            v-if="rowType === 2 && winWidth > 1024"
            style="position: relative; display: flex;"
          >
            <div class="row_type_thumb">
              <img
                :src="
                  `https://img.youtube.com/vi/${item.keys[0]}/mqdefault.jpg`
                "
              />
            </div>
            <div class="row_type_thumb" style="margin-left: 1.6rem;">
              <img
                :src="
                  `https://img.youtube.com/vi/${item.keys[1]}/mqdefault.jpg`
                "
              />
            </div>
          </div>
          <div v-else style="position: relative; display: flex;">
            <div class="row_type_thumb">
              <img
                :src="
                  `https://img.youtube.com/vi/${item.youtubeKey}/mqdefault.jpg`
                "
              />
            </div>
          </div>
        </div> -->
      </li>
    </ul>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-main-party-list-index',
  props: {
    rowType: {
      type: [Number],
      default: 1
    },
    items: {
      type: [Array],
      default: () => []
    }
    // youtubeKeyList: {
    //   type: [Array],
    //   default: () => []
    // }
  },
  // list에 추가
  // computed: {
  //   partyListWithYoutubeKey() {
  //     let copiedYoutubeKey = [...this.youtubeKeyList]
  //     const computedList = this.$props.items.reduce((pv, cv, i) => {
  //       if (this.youtubeKeyList.length === 0) {
  //         return [...pv, cv]
  //       } else {
  //         if (copiedYoutubeKey.length === 0)
  //           copiedYoutubeKey = [...this.youtubeKeyList]
  //         if (
  //           this.winWidth > 679 &&
  //           this.winWidth < 1025 &&
  //           this.rowType === 1
  //         ) {
  //           if (i !== 0 && i % 4 === 0) {
  //             return [
  //               ...pv,
  //               { youtubeKey: copiedYoutubeKey.shift() },
  //               { youtubeKey: copiedYoutubeKey.shift() },
  //               cv
  //             ]
  //           } else {
  //             return [...pv, cv]
  //           }
  //         } else if (this.winWidth > 1024 && this.rowType === 1) {
  //           if (i !== 0 && i % 8 === 0) {
  //             return [
  //               ...pv,
  //               { youtubeKey: copiedYoutubeKey.shift() },
  //               { youtubeKey: copiedYoutubeKey.shift() },
  //               cv
  //             ]
  //           } else {
  //             return [...pv, cv]
  //           }
  //         } else if (this.rowType === 2 && this.winWidth > 1024) {
  //           if (i !== 0 && i % 4 === 0) {
  //             return [
  //               ...pv,
  //               {
  //                 youtubeKey: true,
  //                 keys: [copiedYoutubeKey.shift(), copiedYoutubeKey.shift()]
  //               },
  //               cv
  //             ]
  //           } else {
  //             return [...pv, cv]
  //           }
  //         } else {
  //           if (i !== 0 && i % 4 === 0) {
  //             return [...pv, { youtubeKey: copiedYoutubeKey.shift() }, cv]
  //           } else {
  //             return [...pv, cv]
  //           }
  //         }
  //       }
  //     }, [])

  //     return computedList
  //   }
  // },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
