<template>
  <!--//210713수정끝-->
  <div class="party_list">
    <!--'가로형: row_type'-->
    <ul class="party_list_ul">
      <li
        class="party_list_li"
        @click="fnView(item)"
        :class="{ disabled: Number(item.status) === 1 }"
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="party_tag">
          <div class="left">
            <div
              class="pt_tag logo"
              style="--pt-bg-color: #003471;--pt-txt-color: #fff;"
            >
              {{ item.categoryNm }}
            </div>
            <div class="pt_tag normal nb">
              {{ item.remainQuantity ? `${item.remainQuantity}개` : '0개' }}
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="party_list_thumb">
          <div class="img_area">
            <img :src="item.thumbnail" v-if="item.thumbnail" alt="" />
            <img src="@/assets/static/images/no_image.png" alt="" v-else />
          </div>
        </div>
        <div class="party_ttl">{{ item.title }}</div>
        <div class="party_con">
          <div class="left">
            <div class="party_info">
              <dl>
                <dd
                  :class="computedPer(item.price, item.commonPrice).className"
                >
                  {{ computedPer(item.price, item.commonPrice).per }}
                  <span class="price">{{
                    $Util.formatNumber2(item.commonPrice, { unit: '' })
                  }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <div class="right">
            <div class="party_price">
              <span class="value"
                >{{ $Util.formatNumber2(item.price, { unit: '' }) }} </span
              ><!--띄어쓰기-->
              <span class="unit">원</span>
              {{ Number(item.status) === 1 ? '(마감)' : '' }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-main-market-list-index',
  props: {
    isNick: {
      type: [Boolean],
      default: true
    },
    isDate: {
      type: [Boolean],
      default: true
    },
    isPrice: {
      type: [Boolean],
      default: true
    },
    items: {
      type: [Array],
      default: () => []
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
